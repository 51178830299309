<template>
	<div class="getSystem"></div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		methods: {
			toIndex() {
				this.$router.push({
					path: '/'
				})
			}
		},
		mounted() {
			const isAndroid = /Android|harmonyos|honor|huawei|mi\s|mix\s|redmi|sm-|oppo|vivo/i.test(navigator.userAgent);
			const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
			const isWin = /Windows|Mac/i.test(navigator.userAgent)


			// 定义跳转的URL 
			// let redirectURL = '';

			if (isAndroid) {
				// Android 设备的 URL 
				// redirectURL = '';
				this.$router.replace({
					path: '/Android'
				})
			} else if (isiOS) {
				// iOS 设备的 URL 
				window.location.href = 'https://apps.apple.com/app/heardly-lesstimemoreknowledge/id6473803023';
			} else if (isWin) {
				this.$router.replace({
					path: '/dl'
				})
			} else {
				// 如果不是 Android 或 iOS 设备，你可以提供一个默认的 URL 
				// redirectURL = '';
				this.toIndex()
			}
			// if ('' != redirectURL) {
			// 	// 执行重定向 
			// 	window.location.href = redirectURL;
			// } else {
			// 	this.toIndex()
			// }
		}
	}
</script>

<style lang="less" scoped>

</style>